import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import MaintenanceDoorTabs from '../../components/MaintenanceDoorTabs/MaintenanceDoorTabs';

// Alertify
import {alertify} from 'doorson-ui';

// Routes
import routeMaintenance from '../../../maintenance/pages/MaintenancePage/route';
import routeInfo from '../../pages/MaintenanceDoorInfoPage/route';
import routeWorklog from '../../pages/MaintenanceDoorWorklogPage/route';
import routeConfirmation from '../../pages/MaintenanceDoorConfirmationPage/route';
import routeAttachments from '../../pages/MaintenanceDoorAttachmentsPage/route';
import routeActions from '../../pages/MaintenanceDoorActionsPage/route';

// Libs
import isResolved from '../../lib/isResolved.lib.maintenanceDoor';
import isComplete from '../../lib/isDoorComplete.lib.maintenanceDoor';
import hasPermission from "../../../user/roles/hasPermission.role.user";
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from "../../../user/roles/roles.user";

// Helpers
const routes = {
  [routeInfo()]: routeInfo,
  [routeWorklog()]: routeWorklog,
  [routeConfirmation()]: routeConfirmation,
  [routeAttachments()]: routeAttachments,
  [routeActions()]: routeActions,
};

class MaintenanceDoorTabsContainer extends Component {
  static propTypes = {
    page: PropTypes.string,
    history: PropTypes.object,
    maintenance: PropTypes.object,
    maintenanceDoor: PropTypes.object,
    user: PropTypes.object,
  };

  onTab = (tab) => {
    // TODO: I18n
    const {history, maintenance, maintenanceDoor} = this.props;
    if (tab === 'back') return history.push(routeMaintenance(maintenance.id));
    if (
      tab === routeConfirmation() &&
      !(isResolved(maintenanceDoor) || isComplete(maintenanceDoor))
    ) {
      alertify.info('Finish the worklog first');
      return history.replace(routeWorklog(maintenance.id, maintenanceDoor.id));
    }
    history.push(routes[tab](maintenance.id, maintenanceDoor.id));
  };

  tabs = () => {
    // TODO: I18N
    const {maintenanceDoor, user} = this.props;
    return !maintenanceDoor
      ? []
      : [
        {
          id: 'back',
          label: 'Back',
          icon: 'arrow-left-circle-outline',
        },
        {
          id: routeInfo(),
          label: 'Door Info',
          done: true,
        },
        {
          id: routeWorklog(),
          label: 'Worklog',
          done: isResolved(maintenanceDoor) || isComplete(maintenanceDoor),
        },
        {
          id: routeConfirmation(),
          label: 'Final Confirmation',
          done: isResolved(maintenanceDoor),
        },
        {
          id: routeAttachments(),
          label: 'Attachments',
          done: false,
          icon: 'paperclip',
        },
        hasPermission({
          user,
          roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
        }) && {
          id: routeActions(),
          // label: 'Other',
          done: false,
          icon: 'dots-horizontal',
        },
      ];
  };

  render() {
    return (
      <MaintenanceDoorTabs
        tab={this.props.page}
        tabs={[...this.tabs()].filter(t => !!t)}
        onTab={this.onTab}
      />
    );
  }
}

export default connect((state) => ({
  page: state.layout.subpage,
  user: state.auth.user,
  maintenance: state.maintenance.maintenance,
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
}))(withRouter(MaintenanceDoorTabsContainer));
