import {v4} from 'uuid';

// Action constants
import {SET} from './constants';

// Api
import listApi from '../api/list.api.maintenance';

// Query
import setQuery from '../../api/lib/query.lib.api';

export const set = (data = {}) => ({type: SET, data});

export const updateMaintenance = (maintenance) => (dispatch, getState) => {
  const storedMaintenance = getState().maintenance.maintenance;
  const maintenances = [...getState().maintenance.maintenances].map((int) =>
    int.id === maintenance.id ? maintenance : int
  );
  const newMaintenance =
    !!storedMaintenance && storedMaintenance.id === maintenance.id
      ? maintenance
      : storedMaintenance;
  dispatch({type: SET, data: {maintenance: newMaintenance, maintenances}});
};

export const getMaintenances = (data = {}) => async (dispatch, getState) => {
  const apiID = v4();
  const {search, filter, sort, limit} = getState().maintenance;
  const newSearch = data.search !== undefined ? data.search : search;
  const newFilter = data.filter !== undefined ? data.filter : filter;
  const newSort = data.sort !== undefined ? data.sort : sort;

  dispatch({
    type: SET,
    data: {
      apiID,
      loading: true,
      search: newSearch,
      filter: newFilter,
      sort: newSort,
      offset: 0,
      more: true,
      maintenances: [],
    },
  });

  try {
    const query = setQuery({
      search: newSearch,
      status: newFilter,
      order: newSort,
      rel: ['customer', 'customerBranch', 'doors', 'door'],
      offset: 0,
      limit,
    });
    const maintenances = await listApi(query);
    if (getState().maintenance.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        maintenances,
        loading: false,
        more: maintenances.length === limit,
        offset: limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};

export const getMoreMaintenances = () => async (dispatch, getState) => {
  const {
    loading,
    search,
    filter,
    sort,
    limit,
    offset,
    more,
    apiID,
    maintenances: oldMaintenances,
  } = getState().maintenance;

  if (!more || loading) return;

  dispatch({
    type: SET,
    data: {
      loading: true,
    },
  });

  try {
    const query = setQuery({
      search,
      status: filter,
      order: sort,
      offset,
      limit,
      rel: '*',
    });
    const maintenances = await listApi(query);
    if (getState().maintenance.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        maintenances: [...oldMaintenances, ...maintenances],
        loading: false,
        offset: limit + offset,
        more: maintenances.length === limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};
