import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// alertify
import {alertify} from 'doorson-ui';

// components
import MaintenanceSignMultipleItems from '../../components/MaintenanceSignMultipleItems/MaintenanceSignMultipleItems';
import SignMultipleItemsModal from '../../components/SignMultipleItemsModal/SignMultipleItemsModal';

// file components
import ContractPreviewModal from '../../../file/components/ContractPreviewModal/ContractPreviewModal';

// file lib
import toBase64 from '../../../file/lib/toBase64.lib.file';

// lib
import isResolved from '../../../maintenanceDoor/lib/isResolved.lib.maintenanceDoor';

// maintenanceDoor api
import previewApi from '../../../maintenanceDoor/api/preview.api.maintenanceDoor';
import resolveApi from '../../../maintenanceDoor/api/resolve.api.maintenanceDoor';

// maintenance routes
import isAssigned from '../../lib/isAssigned.lib.maintenance';

class MaintenanceSignMultipleItemsContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    doors: [],
    selected: [],

    signModalVisible: false,
    signing: false,
    technicianSignature: null,
    customerSignature: null,
    technicianEmail: '',
    customerEmail: '',
    signatoryFirstName: '',
    signatoryLastName: '',

    generatingPreview: false,
    previewModalVisible: false,
    preview: null,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const {maintenance} = this.props;
    const doors = this.parseDoors(maintenance.doors);
    this.setState({doors});
  };

  parseDoors = (doors) =>
    [...doors].filter((door) => !isResolved(door) && "COMPLETED" === door.status);

  columns = () => [
    {
      key: 'select',
      label: '',
    },
    {
      key: 'code',
      label: 'Task code',
      span: 1.5,
    },
    {
      key: 'serial',
      label: 'Door serial number',
      span: 1.5,
    },
    {
      key: 'location',
      label: 'Door Location',
      span: 1.5,
      searchable: true,
      sortable: true,
    },
    {
      key: 'type',
      label: 'Door Type',
      span: 3,
      sortable: true,
    },
  ];

  onSelect = (door) => () => {
    const {selected} = this.state;
    const updateSelected = selected.includes(door.id)
      ? [...selected].filter((doorId) => doorId !== door.id)
      : [...selected, door.id];
    this.setState({selected: updateSelected});
  };

  selectAll = () => {
    const {doors} = this.state;
    const selected = [...doors].map(({id}) => id);
    this.setState({selected});
  };

  showSignModal = () => {
    if (!this.state.selected.length) return;
    this.setState({
      signModalVisible: true,
      technicianSignature: null,
      customerSignature: null,
      technicianEmail: '',
      customerEmail: '',
      preview: null,
    });
  };

  hideSignModal = () => {
    const {signing, generatingPreview} = this.state;
    if (signing || generatingPreview) return;
    this.setState({signModalVisible: false});
  };

  sign = async () => {
    const {maintenance} = this.props;
    const {
      signing,
      technicianSignature,
      customerSignature,
      technicianEmail,
      customerEmail,
      signatoryFirstName,
      signatoryLastName,
      selected,
    } = this.state;

    if (signing) return;

    if (!selected.length) return alertify.warning('Select doors to sign');

    if (!technicianSignature || !customerSignature)
      return alertify.warning('Sign and confirm both signatures');

    if (!customerEmail) return alertify.warning('Insert customer email');

    if (!signatoryFirstName || !signatoryLastName)
      return alertify.warning('Insert customer info');

    this.setState({signing: true});

    try {
      const newMaintenanceDoors = await resolveApi(
        maintenance.id,
        {
          maintenanceDoorIds: selected,
          technicianSignature,
          customerSignature,
          signatoryFirstName,
          signatoryLastName,
          companyContractReceivers: !!technicianEmail ? [technicianEmail] : [],
          customerContractReceivers: !!customerEmail ? [customerEmail] : [],
        },
        {rel: '*'}
      );
      const doors = [...this.state.doors].filter(
        (door) => ![...newMaintenanceDoors].find(({id}) => door.id === id)
      );
      this.setState({
        signing: false,
        selected: [],
        signModalVisible: false,
        doors,
      });
      alertify.success('Doors signed');
    } catch (error) {
      this.setState({signing: false});
    }
  };

  generatePdf = async () => {
    const {maintenance} = this.props;
    const {generatingPreview, selected, preview} = this.state;

    if (generatingPreview) return;

    if (!!preview) return this.setState({previewModalVisible: true});

    this.setState({generatingPreview: true});

    try {
      const pdfBlob = await previewApi(maintenance.id, [...selected]);
      const pdf64 = await toBase64(pdfBlob);
      const pdf = pdf64.startsWith('data:application/octet-stream')
        ? pdf64.replace('data:application/octet-stream', 'data:application/pdf')
        : pdf64;
      this.setState({
        generatingPreview: false,
        previewModalVisible: true,
        preview: pdf,
      });
    } catch (error) {
      this.setState({generatingPreview: false, previewModalVisible: false});
      alertify.error('Contract could not be generated');
    }
  };

  closePdfPreview = () => this.setState({previewModalVisible: false});

  change = (key) => (value) => {
    if (this.state.signing) return;
    this.setState({[key]: value});
  };

  canChange = () => isAssigned(this.props.user, this.props.maintenance);

  render() {
    const {maintenance} = this.props;
    const {customerBranch} = maintenance;
    const suggestionEmails = ((customerBranch || {}).emails || []).map(
      (email) => ({value: email, label: email})
    );
    const {
      customerEmail,
      customerSignature,
      doors,
      generatingPreview,
      preview,
      previewModalVisible,
      selected,
      signatoryFirstName,
      signatoryLastName,
      signing,
      signModalVisible,
      technicianEmail,
      technicianSignature,
    } = this.state;
    return (
      <Fragment>
        <MaintenanceSignMultipleItems
          columns={this.columns()}
          canChange={this.canChange()}
          maintenance={maintenance}
          doors={doors}
          selected={selected}
          onSelect={this.onSelect}
          onSelectAll={this.selectAll}
          onSign={this.showSignModal}
        />
        <SignMultipleItemsModal
          customerEmail={customerEmail}
          suggestionEmails={suggestionEmails}
          customerSignature={customerSignature}
          generatingPreview={generatingPreview}
          onChange={this.change}
          onClose={this.hideSignModal}
          onPdf={this.generatePdf}
          onSign={this.sign}
          signatoryFirstName={signatoryFirstName}
          signatoryLastName={signatoryLastName}
          signing={signing}
          technicianEmail={technicianEmail}
          technicianSignature={technicianSignature}
          visible={signModalVisible && !previewModalVisible}
        />

        <ContractPreviewModal
          loading={generatingPreview}
          visible={previewModalVisible}
          title="Contract Preview"
          preview={preview}
          onClose={this.closePdfPreview}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({ user: state.auth.user }))(withRouter(MaintenanceSignMultipleItemsContainer));
